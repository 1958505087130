@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Black.ttf");
  font-weight: 900;
}

body {margin: 0;}
#root {
  display: flex;
  height: 100vh;
  overflow: auto;
  background: rgb(26,48,101);
  background: linear-gradient(180deg, rgba(26,48,101,1) 0%, rgba(21,40,84,1) 50%); 
}